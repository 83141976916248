/* Popup.css */
.popup-container {
  position: relative;
  text-align: center;
  margin: 5px;
  margin-top: 20px !important;
}

.popup-button {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1; /* Ensure the popup is above the button */

}

.dropdown-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  color: white;
  display: flex;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
  align-items: center; /* Vertically align children */

}

.arrow {
  margin-left: 5px; /* Adjust this value to control spacing between text and arrow */
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  margin-right: 20px;
  margin-top: 150px;
  padding-bottom: 10px;

}

.open .dropdown-content {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-top: 15px;
  margin-top: 50px;
}

.popup {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  border-radius: 5px;
  z-index: 1000;
  z-index: 2; /* Ensure the popup is above the button */

}

.popup-content {
  padding: 10px;
}

.show-hello-button {
  border-radius: 10px;
  padding: 5px 10px;
  background-color: black;
  color: white;
  border: 1px solid black; /* Add a border */
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2); /* Add a right and bottom shadow */
}


.dark-mode {
  background-color: #000;
  color: #fff;
  border-color: white;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);

}

.dark-button{
  background: white;
  color: black;
}

.dark-mode-text {
  background-color: #000;
  color: #fff;
  border-color: white;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.dark-mode-button {
  background-color: #333;
  color: #fff;
  border-color: white;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);

}
