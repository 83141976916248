.deployment-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
    border: 2px solid grey;
    padding: 5px;
    width: 800px;
    display: inline-block;
    margin-left: 0px;
    max-height: 600px;
    border-radius: 10px !important;
    overflow-x: auto;
    border-collapse: separate;
    border-radius: 10px !important;
  }

.table-header {
  background-color: #f0f0f0;
  text-align: left;
  padding: 10px;
}

.table-cell {
  padding: 10px;
  border: 1px solid #ccc;
}


.table-cell2 {
  padding: 10px;
  width: 150px;
  border: 1px solid #ccc;
}

.select-input {
  width: 100%;
  padding: 5px;
}

.number-input {
  width: 100%;
  padding: 5px;
}

.string-input {
  width: 150px;
  padding: 5px;
}

.number-input{
  width: 50px
}
