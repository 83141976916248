

.pipeline-side-menu {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  height: 630px;
  margin-bottom: -200px;
  top: 0;
  left: 0;
  margin-left: -40px;
  margin-right: 15px;
  bottom: 0;
  background-color: #F8FAFC;
  border-right: 2px solid lightslategrey;
  z-index: 500;
  margin-top: -20px;
  width: 250px;
  padding: 20px;
  overflow-y: auto;
  overflow-x: auto;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  display: inline-block; /* Prevent vertical expansion */
}


.pipeline-side-menu:hover {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  height: 630px;
  margin-bottom: -200px;
  top: 0;
  left: 0;
  margin-left: -40px;
  margin-right: 15px;
  bottom: 0;
  background-color: #F8FAFC;
  border-right: 2px solid lightslategrey;
  z-index: 500;
  margin-top: -20px;
  width: 250px;
  padding: 20px;
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  overflow-y: auto;
  overflow-x: auto;
  display: inline-block; /* Prevent vertical expansion */
}



.pipeline-side-menu2 {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  height: 630px;
  margin-bottom: -200px;
  top: 0;
  left: 0;
  margin-left: -40px;
  margin-right: 15px;
  bottom: 0;
  background-color: #F8FAFC;
  border-right: 2px solid lightslategrey;
  z-index: 500;
  margin-top: 20px;
  width: 250px;
  padding: 20px;
  overflow-y: auto;
  overflow-x: auto;
  transition: width 0.05s; /* Add a smooth transition effect for width changes */
  display: inline-block; /* Prevent vertical expansion */
}


.pipeline-side-menu3 {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  height: 630px;
  margin-bottom: -200px;
  top: 0;
  left: 0;
  margin-left: -40px;
  margin-right: 15px;
  bottom: 0;
  background-color: #F8FAFC;
  border-right: 2px solid lightslategrey;
  z-index: 500;
  margin-top: 0px;
  width: 250px;
  padding: 20px;
  overflow-y: auto;
  overflow-x: auto;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  display: inline-block; /* Prevent vertical expansion */
}

.pipeline-side-menu3:hover {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  height: 630px;
  margin-bottom: -200px;
  top: 0;
  left: 0;
  margin-left: -40px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */

  margin-right: 15px;
  bottom: 0;
  background-color: #F8FAFC;
  border-right: 2px solid lightslategrey;
  z-index: 500;
  margin-top: 0px;
  width: 250px;
  padding: 20px;
  overflow-y: auto;
  overflow-x: auto;
  display: inline-block; /* Prevent vertical expansion */
}


.structureInstanceWrap{
  margin-top: 00px;
  margin-left: -215px;
}

.pipeline-side-menu ul {
  list-style: none;
  padding: 0;
}

.pipeline-side-menu2 ul {
  list-style: none;
  padding: 0;
}

.pipeline-side-menu3 ul {
  list-style: none;
  padding: 0;
}

.pipeline-side-menu li {
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #556B2F; /* Green color */

  padding: 2px;
  border-radius: 3px;
}

.pipeline-side-menu li {
  margin-bottom: 10px;
  font-size: 20px;
  background-color: #F8FAFC;
  border-bottom: 1px solid black;

  cursor: pointer;
  padding: 10px;
  /* border: .5px solid black; /* Set border style */
}


.pipeline-side-menu li:hover {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  color: white;
  background-color: #556B2F; /* Green color */
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}

.pipeline-side-menu3 li {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  background-color: #F8FAFC;
  border-bottom: 1px solid black;

  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}

.pipeline-side-menu3 li:hover {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  color: white;
  
  background-color: #556B2F; /* Green color */
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}


.pipeline-side-menu2 li {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  color: black;
  background-color: #F8FAFC;
  border-bottom: 1px solid black;
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}

.pipeline-side-menu2 li:hover {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  color: white;
  background-color: #556B2F; /* Green color */
  border-bottom: 1px solid black;
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}


.sidemenu-title {
  margin-top: -15px;
  margin-bottom: 5px;
}

.pipelineInstance{
  margin-top: 00px;
  /* margin-left: -220px; */
}

/* Add more styling as needed */
.prototype-side-menu.resizing {
  cursor: col-resize; /* Change cursor to resizing cursor when resizing */
  user-select: none; /* Disable text selection during resizing */
  pointer-events: none; /* Ignore pointer events during resizing */
  transition: none; /* Disable transition during resizing */
}

.prototype-side-menu.resizing li {
  pointer-events: auto; /* Allow pointer events for li elements during resizing */
}

.selected {
  background-color: skyblue !important; /* Change to the desired grey color */
  color: black; /* Text color for the selected item */
}

.elementTitle{
  margin-top: 00px;
  font-size: 20px;
  margin-bottom: 40px;
  color: black;
}


.elementTitle8{
  margin-top: 40px;
  font-size: 20px;
  margin-bottom: 40px;

}
/* Add dark mode styles for the homepage */
.dark-mode {
  background-color: #333;
  color: #fff;
  border-color: white; /* Add this line to change the border color to white in dark mode */
}

.dark-mode-text {
  color: #fff;
}

.dark-mode-button {
  background-color: #333;
  color: #fff;
}
