/* PrototypeSideMenu.css */
.prototype-side-menu {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  height: 630px;
  margin-bottom: -200px;
  top: 0;
  left: 0;
  margin-left: -40px;
  margin-right: 15px;
  bottom: 0;
  background-color: #F8FAFC;
  border-right: 2px solid lightslategrey;
  z-index: -500;
  margin-top: 80px;
  width: 250px;
  padding: 20px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  overflow-y: auto;
  overflow-x: auto;
  display: inline-block; /* Prevent vertical expansion */
}

.prototype-side-menu8 {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  height: 635px;
  margin-bottom: -200px;
  top: 0;
  left: 0;
  margin-left: -40px;
  margin-right: 15px;
  bottom: 0;
  background-color: #F8FAFC;
  border-right: 2px solid lightslategrey;
  z-index: -800;
  margin-top: 76px;
  width: 250px;
  padding: 20px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  overflow-y: auto;
  overflow-x: auto;
  display: inline-block; /* Prevent vertical expansion */
}

.prototype-side-menu8:hover {
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 #003366; /* Add a shadow to the right and bottom */
}


.prototype-side-menu8 li:hover {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease; /* Smooth transition on hover */

  background-color: floralwhite;
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}

.prototype-side-menu8 ul {
  list-style: none;
  padding: 0;
}

.prototype-side-menu8 li {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  background-color: #F8FAFC;
  border-bottom: 1px solid black;
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}

.prototype-side-menu8 li:hover {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  color: white;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  background-color: #556B2F; /* Green color */
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}



.prototype-side-menu:hover {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  height: 630px;
  margin-bottom: -200px;
  top: 0;
  left: 0;
  margin-left: -40px;
  margin-right: 15px;
  bottom: 0;
  background-color: #F8FAFC;
  border-right: 2px solid lightslategrey;
  z-index: -500;
  margin-top: 80px;
  width: 250px;
  padding: 20px;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 #003366; /* Add a shadow to the right and bottom */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  overflow-y: auto;
  overflow-x: auto;
  display: inline-block; /* Prevent vertical expansion */
}

.prototype-side-menu5 {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  height: 630px;
  margin-bottom:-590px;
  left: 0;
  margin-left: 80px;
  margin-right: 15px;
  bottom: 0;
  border-radius: 10px;
  background-color: #F8FAFC;
  border-right: 2px solid white;
  border: 2px solid white;

  z-index: -500;
  margin-top: 0px;
  width: 300px;
  padding: 20px;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: auto;
  transition: width 0.05s; /* Add a smooth transition effect for width changes */
  display: inline-block; /* Prevent vertical expansion */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}

.prototype-side-menu5:hover {
  box-shadow: 10px 10px 0 #003366; /* Add a shadow to the right and bottom */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}

.prototype-side-menu6:hover {
  box-shadow: 10px 10px 0 #003366; /* Add a shadow to the right and bottom */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}



.prototype-side-menu6 {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  height: 630px;
  margin-bottom:-600px;
  top: 0;
  left: 0;
  margin-left: -40px;
  margin-right: 15px;
  bottom: 0;
  background-color: #F8FAFC;
  border-right: 2px solid lightslategrey;
  z-index: -500;
  margin-top: 95px;
  width: 300px;
  padding: 20px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: auto;
  transition: width 0.05s; /* Add a smooth transition effect for width changes */
  display: inline-block; /* Prevent vertical expansion */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}


.prototype-side-menu5 .additional-button {
  margin-top: 10px; /* Adjust as needed */
  margin-top: 50px;
  margin-left: 10px; /* Adjust as needed */
  background-color: #3498db; /* Example background color */
  color: #fff; /* Example text color */
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.prototype-side-menu5 .additional-button:hover {
  background-color: #2980b9; /* Example hover background color */
}

.structureInstanceWrap{
  margin-top: 00px;
  margin-left: -215px;
}

.reportHeader{
  display: block;
  border-right: 1px solid white;

  padding-left: 5px;
  padding-right: 5px;
  font-size: 1.2em;
  padding: 5px;
  color: white;

  justify-content: center; /* Horizontal centering */
align-items: center; /* Vertical centering */
transition: background-color 0.3s ease; /* Smooth transition on hover */

  text-align: center;

}

.reportHeader:hover{
  background-color: #556B2F; /* Green color */
  color: white !important;
  padding: 5px;
  transition: background-color 0.3s ease; /* Smooth transition on hover */

}

.renderingHeader{
  background: white;
  padding: 5px;
}

.reportHeaderWrapper{
  display: flex;
  text-align: center;
  margin-top: 50px;
  margin-left: 800px;
  justify-content: center; /* Horizontal centering */
align-items: center; /* Vertical centering */
  margin-bottom: 20px;
}

.reportWrap{
  display: block;
width: 1050px;
}

.structureInstance{
  margin-top: -80px;
}

.prototype-side-menu ul {
  list-style: none;
  padding: 0;
}

.prototype-side-menu li {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  color: black;
  margin-left: 0px;
  border-bottom: 1px solid black;
  background-color: #F8FAFC;
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}

.prototype-side-menu li:hover {
  margin-bottom: 10px;
  font-size: 20px;
  background-color: #556B2F; /* Green color */
  color: white;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease; /* Smooth transition on hover */

  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}

.prototype-side-menu6 ul {
  list-style: none;
  padding: 0;
}

.prototype-side-menu6 li {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid black;
  background-color: #F8FAFC;
  /* border: .5px solid black; /* Set border style */
}


.easyWrap{
    display: run-in;
}


.prototype-side-menu6 li:hover {
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  color: white;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  background-color: #556B2F; /* Green color */
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}



.prototype-side-menu5 ul {
  list-style: none;
  padding: 0;
}

.prototype-side-menu5 li {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 250;
  cursor: pointer;
  padding: 10px;

  background-color: #F8FAFC;
  border-bottom: 1px solid black;
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}

.prototype-side-menu5 li:hover {
  margin-bottom: 10px;
  font-size: 20px;
  color: white !important;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  background-color: #556B2F !important; /* Green color */
  /* border: .5px solid black; /* Set border style */
  border-radius: 3px;
}

.easy{
  display: flex;
  font-size: 20px;
  border-bottom: 1px solid black;
  padding: 5px;
  margin-left: 10px;
  font-weight: 250;
}

.easy3{
  display: flex;
  font-size: 22px;
  border-bottom: 1px dashed black;
  padding: 5px;
  margin-left: 10px;
  font-weight: 250;
}

.easy2{
  display: flex;
  margin-left: 10px;
}

.sidemenu-title {
  margin-top: -15px;
  margin-bottom: 5px;
}

/* Add more styling as needed */
.prototype-side-menu.resizing {
  cursor: col-resize; /* Change cursor to resizing cursor when resizing */
  user-select: none; /* Disable text selection during resizing */
  pointer-events: none; /* Ignore pointer events during resizing */
  transition: none; /* Disable transition during resizing */
}

.prototype-side-menu.resizing li {
  pointer-events: auto; /* Allow pointer events for li elements during resizing */
}

.selected {
  background-color: grey; /* Change to the desired grey color */
  color: black; /* Text color for the selected item */
}

.selected2 {
  background-color: #556B2F; /* Green color */
  color: white; /* Text color for the selected item */
}

.elementTitle{
  margin-top: 00px;
  margin-bottom: 80px
}

.elementTitle6{
  margin-bottom: 40px;
  margin-left: 10px;
  padding-right: 10px;
  border-right: 1px solid black;
  font-size: 20px;
  transition: background-color 0.3s ease; /* Smooth transition on hover */

}

.elementTitle6:hover{
  background-color: #556B2F; /* Green color */
  color: white !important;
  transition: background-color 0.3s ease; /* Smooth transition on hover */

}


/* Add dark mode styles for the homepage */
.dark-mode {
  background-color: #333;
  color: #fff;
  border-color: white; /* Add this line to change the border color to white in dark mode */
}

.dark-mode-text {
  color: #fff;
}

.dark-mode-button {
  background-color: #333;
  color: #fff;
}
