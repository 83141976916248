.arrive-button {
  background-color: black;
  color: white;
  border-radius: 10px;
  font-size: 18px; /* Increase the font size to make it bigger */
  border: 2px solid black;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
  padding: 45px 80px; /* Increase the padding to make it bigger */
  display: block;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  margin: 30px auto; /* Center the button horizontally */
  cursor: pointer;
}

@media (max-width: 768px) {
  .button {
    font-size: 20px; /* Further decrease font size for smaller screens */
    padding: 15px 30px; /* Adjust padding for smaller screens */
  }

  .arrive-button {
    font-size: 18px; /* Further decrease font size for mobile */
    padding: 15px 30px; /* Adjust padding for mobile */
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2); /* Smaller shadow for mobile */
  }
}


.arrive-button:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  font-size: 18px; /* Increase the font size to make it bigger */
  border: 2px solid black;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  box-shadow: 15px 15px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
  padding: 45px 80px; /* Increase the padding to make it bigger */
  display: block;
  margin: 30px auto; /* Center the button horizontally */
  cursor: pointer;
}

.fillerClass{
  margin-top: -100px;
  opacity: .01;
  margin-left: 200px;
}

.checkboxWrap{
  margin-left: 40px;
  font-size: 1.2em;
  display: relative;
}

/* Add this CSS to your stylesheet */
.modalTitle{
  color: black;
  font-size: 1.2em;

  margin-bottom: 20px;
}
/* Style the modal content */
.modal-content {
  background-color: white;
  padding: 20px;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal2 {
  position: fixed;
  margin-top: 300px;
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the modal */
  background-color: white;
  padding: 50px;
  border: 2px solid #333;
  border-radius: 5px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  max-width: 400px; /* Set the maximum width of the modal */
}


.modal2:hover {
  position: fixed;
  margin-top: 300px;
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the modal */
  background-color: white;
  padding: 50px;
  border: 2px solid #333;
  border-radius: 5px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
  max-width: 400px; /* Set the maximum width of the modal */
}

/* Add styling for the modal */
.modal {
  position: block;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the modal */
  background-color: white;
  padding: 20px;
  border: 2px solid #333;
  border-radius: 5px;
  margin-top: 250px;
  margin-left: 50px;
  height: 400px;
  z-index: 9999999 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 1200px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  max-width: 1200px; /* Set the maximum width of the modal */
}

.publishing{
  font-size: 1.1em;
  margin-left: 200px;
}

.modal7 {
  position: fixed;
  margin-top: 300px;
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the modal */
  background-color: white;
  padding: 20px;
  border: 2px solid #333;
  border-radius: 5px;
  margin-top: 500px;
  height: 300px;
  z-index: 9999999 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  max-width: 1200px; /* Set the maximum width of the modal */
}

.modal:hover{
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
}

.modal7:hover{
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
}

/* Style the modal's title (h2) */
.modal h2 {
  font-size: 24px;
  margin: 0;
  padding-bottom: 20px;
}

/* Style the Google Login button inside the modal */
.modal .google-login-button {
  background-color: #4285f4; /* Google blue color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block; /* Make it a block-level element */
  width: 100%; /* Take up the full width available */
  text-align: center;
}

/* Style the close button */
.modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Add dark mode styles for the homepage */
.dark-mode {
  background-color: #000;
  color: #fff;
  border-radius: 10px;
}

.dark-mode-text {
  color: #fff;
}

.dark-mode-button {
  background-color: white;
  color: black;
  border-color: white;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);

}

.content-grid {
  margin-top: 205px;
  margin-left: 0px;
  margin-right: 5px;
  background-color: #001522;
  display: grid;
  width: 105%;
  margin-bottom: -320px;
  bottom: 0;
  border-radius: 10px;
  grid-template-columns: 200px 1fr; /* Adjust the width of the side menu as needed */
  grid-gap: 20px; /* Add spacing between columns */
  align-items: start; /* Align items to the top of the grid */
  padding: 20px; /* Add padding as needed */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  box-sizing: border-box; /* Include padding in the width */
}
  /* Apply media query to adapt the layout for smaller screens (e.g., max-width: 768px) */
  @media (max-width: 768px) {
    .content-grid {grid-template-columns: 1fr; /* Stack columns for smaller screens */
  }
}




.prototype-container {
  display: grid;
  grid-template-columns: 200px 1fr; /* Adjust the width of the side menu as needed */
  grid-gap: 20px; /* Add spacing between columns */
  align-items: start; /* Align items to the top of the grid */
  padding: 20px; /* Add padding as needed */
  box-sizing: border-box; /* Include padding in the width */
  border-radius: 0px !important;
  transform: scale(0.67);
  margin-bottom: -20px;
  background-color: #001522;
  width: 140%;
  transform-origin: 0 0;
}
