/* TermsOfService.css */
.center-div {
  text-align: left;
  margin-top: 100px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: left;

}


.aboutJinsei{

  color: white;
  margin-bottom: 40px;
  margin-right: 20px;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .center-div {
    margin-right: 20px;
    margin-left: 20px;

  }
}
