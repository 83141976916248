  /* Add styles for the header */
  .header {
    background-color: #F8FAFC;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 0;
    transition: opacity 0.5s; /* Transition the opacity property over 0.5 seconds */
    width: 106%; /* Increase the width to compensate for the scaling */
    transform: scale(0.92);
    transform-origin: 0 0;
    transition: opacity 0.5s ease, visibility 0.5s ease;

  }

  @media (min-width: 1307px) {
    .header {
      width: 107.5%;
    }
  }
  

  .socials{
    height: 25px;
    margin-left: 5px;
    margin-top: 5px;
    width: 25px;
  }


  .homepage-header {
    background-color: #F8FAFC;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 0;
    transition: opacity 0.5s; /* Transition the opacity property over 0.5 seconds */
    width: initial; /* Set width to its default value (null) */
  transform: initial; /* Set transform to its default value (null) */
  transform-origin: initial; /* Set transform-origin to its default value (null) */

  }

  .button {
    color: black;
    text-decoration: none;
    font-size: 16px;

    padding: 10px 5px; /* Adjust the vertical and horizontal padding */
    display: block; /* Display buttons as block elements */
    margin-bottom: 5px; /* Reduce the vertical margin */
    background-color: transparent; /* Set the background to be transparent */
    border-bottom: 1px black solid; /* Remove the border */
  }
  /* @media (min-width: 1367px) {
    .button {
      width: 100%;
    }
  } */
  .title {
    text-align: center;
    font-size: 20px;
    margin-top: 0px;
    display: flex;
  align-items: center;
  }

  .title {
    margin-left: 30px;
    color: black;
    display: flex;
  align-items: center;
  }

  .title h1{
    font-size: 32px;
    margin-left: -40px;
  }

  @media (max-width: 767px) {
    .title {
      margin-left: 50px;
    }
  }




  /* Style for the header image */
  .header-image {
    width: 80px;
    height: 80px;
    margin: 0px;
  }

  /* Mobile menu styles */
  .menu-container {
    position: fixed;
    top: 5px; /* Adjust for the header height */
    left: -220px; /* Start menu off-screen to the left */
    width: 220px; /* Adjust the width as needed */
    background-color: white;
    transition: left 0.4s ease-in-out; /* Change "right" to "left" */
    z-index: 1000;
    border-radius: 7px;
    height: 175px; /* Adjust the height to your preference */

  }

  /* Set height to 20% on mobile screens */
  @media (max-width: 767px) {
    .menu-container {
      height: 238px; /* Adjust the height to your preference */
    }
  }

  .menu-container.open {
    border: 2px navy solid;
    left: 00px; /* Change "right" to "left" to bring the menu in from the left when open */
    top: 105px;
  }

  @media (max-width: 767px) {
    .menu-container.open {
      left: 0px; /* Change "right" to "left" to bring the menu in from the left when open */
      top: 105px;
    }
  }



.menu-button {
  color: black;
  font-size: 20px;
  margin-left: 40px;
  margin-right: 20px;
  cursor: pointer;
}

.menu-button2 {
  color: black;
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .menu-button2 {
    margin-right: 70px;
  }
}




.dark-button {
  background-color: black; /* Dark background color */
  color: #fff; /* Text color in dark mode */
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 10px;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .dark-button {
    padding: 8px 16px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
    margin: 10px 0;
    margin-right: 20px; /* Center the button horizontally and add space between buttons */
  }
}

.dark-mode1 {
  background-color: #000;
  color: #fff;
  border-radius: 0px;
}

.title5{
  margin-top: 10px;
  margin-left: 20px;
  color: black;
}
@media (max-width: 767px) {
  .title5 {
    display: none;
  }
}

.title16{
  margin-left: 40px;
  color: black;
}

.cornerWrap{
  display: flex;
}

.button8 {
  color: black;
  font-size: 20px; /* Further decrease font size for smaller screens */
  padding: 15px 30px; /* Adjust padding for smaller screens */
}

@media (max-width: 768px) {
  .button8 {
    font-size: 20px; /* Further decrease font size for smaller screens */
    padding: 15px 30px; /* Adjust padding for smaller screens */
  }
}
@media (max-width: 768px) {
  .cornerWrap {
    padding: 8px 16px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
    margin-right: 50px; /* Center the button horizontally and add space between buttons */
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

.header.hidden {
  visibility: hidden; /* Hide the element without affecting layout */
  opacity: 0; /* Set opacity to 0 when hidden */
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

/* Add styles for dark mode */
.dark-mode {
  background-color: #333; /* Replace with your dark mode background color */
  color: #fff; /* Replace with your dark mode text color */
  border-color: white;

}

.dark-mode-text {
  color: #fff; /* Text color in dark mode */
}


/* Rest of your styles... */
