/* Timeline.css */
.timeline-title {
  font-size: 32px;
  color: white;
  margin-top: 150px;
  opacity: 0.95;
}

.dark-mode{
  color: white;
}

.title2 {
  font-size: 20px;
  margin-bottom: 40px;
  color: #F5F5F5;
  opacity: 0.9;
}

.dark-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin: 5px;
  display: inline-block;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5), 1.5px 3px 0 white;
}

@media (max-width: 768px) {
  .dark-button {
    padding: 8px 16px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
    margin-right: 50px; /* Center the button horizontally and add space between buttons */
  }
}

.dark-button {
  background-color: lightcyan; /* Green color */
  color: black;
  border-radius: 15px;
  font-size: 26px; /* Increase the font size to make it bigger */
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 0px solid black;
  border-top: 0px solid black;
  padding: 5px 10px; /* Increase the padding to make it bigger */
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */

  margin: 20px auto; /* Center the button horizontally */
  margin-top: 50px;
  margin-right: 10px;
  cursor: pointer;
}

.dark-button2 {
  background-color: lightcyan; /* Green color */
  color: black;
  border-radius: 15px;
  font-size: 26px; /* Increase the font size to make it bigger */
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 0px solid black;
  border-top: 0px solid black;
  padding: 5px 10px; /* Increase the padding to make it bigger */
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */

  margin: 20px auto; /* Center the button horizontally */
  margin-top: 50px;
  cursor: pointer;
}


.dark-button:hover {
  background-color: #556B2F; /* Green color */
  color: white;

  border-radius: 15px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  box-shadow: 10px 10px 0 rgba(255, 255, 255, 0.2); /* Light gray shadow */
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
  cursor: pointer;
}

.timelinePosition {
  margin-top: 10px;
  margin-bottom: 100px;
}

div iframe {
  height: 450px;
  border-radius: 20px;
  width: 650px;
}

@media screen and (min-width: 768px) {
  div iframe {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) {
  div iframe {
    width: 100%;
  }
}

.button-container {
  margin-top: 100px;
  margin-bottom: 0px;
  display: flex;
}

.button-container2 {
  margin-top: 50px;
  margin-bottom: 100px;
}

.content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content .title2,
.content .timelinePosition {
  text-align: center;
}
.frame {
  height: 500px;
}

.timelinePosition {
  margin-top: 10px;
  margin-bottom: 100px;
}
