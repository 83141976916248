/* PrototypeHeader.css */
.prototype-header {
  background-color: white;
  color: black;
  /* border: 2px solid black; /* Set border style */
  padding: 10px;
  display: flex;
  width: 106%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  margin-top: 125px;
  margin-right:0px;
  padding-top: 20px;
  top: 0;
  left: 0;
  right: 0;

  padding-bottom: 20px;
  z-index: 500;
  background-color: #F8FAFC;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}




.prototype-header2 {
  background-color: #F8FAFC;
  color: black;
  /* border: 2px solid black; /* Set border style */
  border-bottom: 2px solid lightslategrey;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  margin-top: 255px;
  margin-bottom: 15px;
  margin-left:0px;
  margin-right:5px;
  top: 0;
  left: 0;
  right: 0;
  background-color: #001522;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  width: 105%;
}

.prototype-header2:hover {
  color: black;
  /* border: 2px solid black; /* Set border style */
  border-bottom: 2px solid lightslategrey;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  margin-top: 255px;
  margin-bottom: 15px;
  margin-left:0px;
  margin-right:5px;
  top: 0;
  left: 0;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 5px 5px 0 #003366; /* Add a shadow to the right and bottom */
  right: 0;
  background-color: #001522;
  width: 104%;
}


.stickyProtoHeader{
  display: flex;
}

.prototype-header h2 {
  margin: 0;
  font-size: 24px;
}

.importButton1{
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  color: white;
  box-shadow: 5px 5px 0 #003366; /* Add a shadow to the right and bottom */
  margin-right: 00px;
  margin-top: 15px !important;
}


.prototype-container {
  min-height: 100vh; /* Set a minimum height to ensure the footer is at the bottom */
}

.header-image{
  width: 80px;
  margin-left: 15px;
  height: 80px;
}

.header-image:hover{
  width: 80px;
  opacity: .5;
  height: 80px;
}

.selected-tab{
  background-color: skyblue !important;
  color: black !important;
}


.button-wrap{
  display: flex;
  margin-left: -100px;
}

.popup-container {
  position: relative;
  text-align: center;
  margin: 5px;
  margin-top: 20px !important;
  display: flex;
}

.title9{
  margin-left: 00px;
  color: white;
  margin-right: 0px;
}

.dropdown-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px !important;
  cursor: pointer;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  color: white;
  display: flex;
  box-shadow: 5px 5px 0 #003366; /* Add a shadow to the right and bottom */
}

.dropdown-button2 {
  border: none;
  padding: 10px !important;
  cursor: pointer;
  background-color: #556B2F; /* Green color */
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
  color: white;
  transition: background-color 0.5s ease; /* Smooth transition on hover */

  display: flex;
}

.dropdown-button2:hover {
  border: none;
  padding: 10px !important;
  transition: background-color 0.5s ease; /* Smooth transition on hover */

  cursor: pointer;
  background-color: skyblue;
  padding: 10px;
  margin-left: 10px;
  color: black;
  display: flex;
}


.dropdown-button3 {
  border: none;
  padding: 10px !important;
  cursor: pointer;
  background-color: #556B2F; /* Green color */
  padding: 10px;
  border-radius: 5px;
  margin-left: 0px;
  color: white;
  transition: background-color 0.5s ease; /* Smooth transition on hover */

  display: flex;
}

.dropdown-button3:hover {
  border: none;
  padding: 10px !important;
  transition: background-color 0.5s ease; /* Smooth transition on hover */

  cursor: pointer;
  background-color: skyblue;
  padding: 10px;
  margin-left: 0px;
  color: black;
  display: flex;
}


.dropdown-button8 {
  border: none;
  padding: 10px !important;
  cursor: pointer;
  background-color: #556B2F; /* Green color */
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: white;
  transition: background-color 0.5s ease; /* Smooth transition on hover */

  display: flex;
}

.dropdown-button8:hover {
  border: none;
  padding: 10px !important;
  transition: background-color 0.5s ease; /* Smooth transition on hover */

  cursor: pointer;
  background-color: skyblue;
  padding: 10px;
  color: black;
  display: flex;
}

.dropdown-button9 {
  border: none;
  padding: 10px !important;
  cursor: pointer;
  background-color: #556B2F; /* Green color */
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 10px;

  color: white;
  transition: background-color 0.5s ease; /* Smooth transition on hover */

  display: flex;
}

.dropdown-button9:hover {
  border: none;
  padding: 10px !important;
  transition: background-color 0.5s ease; /* Smooth transition on hover */

  cursor: pointer;
  background-color: skyblue;
  padding: 10px;
  color: black;
  display: flex;
}
.button-wrap2{
  display: flex;
  margin-left: -170px;
}

.button-wrap3 {
  display: flex;
  justify-content: center; /* Align items horizontally in the center */
  align-items: center; /* Align items vertically in the center */
  margin-left: 00px; /* Adjust as needed */
}

.title{
  font-size: 14px;

}

.titlez{
  font-size: 14px;
  margin-top: 15px;
}

.container{
  display: flex;
}

.button-group {
  margin-right: 20px; /* Adjust as needed */
  display: flex;
  padding: 5px;

  border: 1.6px solid #556B2F; /* Green color */
  border-radius: 8px; /* Adjust as needed for rounded corners */
}

.button-group2 {
  margin-right: 20px; /* Adjust as needed */
  display: flex;
  padding: 5px;

  border: 1.6px solid #556B2F; /* Green color */
  border-radius: 8px; /* Adjust as needed for rounded corners */

}
.title-input{
  margin-top: 25px;
 height: 25px;
}

/* Add dark mode styles for the homepage */
.dark-mode {
  background-color: grey;
  color: #fff;
  border-color: white; /* Add this line to change the border color to white in dark mode */
}

.dark-mode-text {
  color: #fff;
}

.dark-mode-button {
  background-color: #333;
  color: #fff;
}

/* Add more styling as needed */
