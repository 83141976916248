.homepage-container {
  text-align: center;
  padding: 20px;
  margin-top: 50px;
  background-size: 30%; /* Adjust the percentage as needed for the desired zoom level */
  background-color: white;
  margin-bottom: 300px;

  /* background: url('blue.png') repeat;

  background-position: right 50px top 60px; */
  /* background-image: url('./backgroundImageHomepage.png'); /* Corrected syntax with url() */
}
/* Media query for mobile devices */
  @media (max-width: 768px) {
  .homepage-container {
    margin-right: 0px;
    margin-bottom: 300px;
  }
}

.api-button {
  background-color: black;
  color: white;
  border-radius: 30px;
  font-size: 36px; /* Increase the font size to make it bigger */
  border: 2px solid black;
  box-shadow: 20px 20px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
  padding: 50px 160px; /* Increase the padding to make it bigger */
  display: block;
  margin: 20px auto; /* Center the button horizontally */
  cursor: pointer;
  margin-top: 200px;
}

.google-login-button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}


/* Add styles for the "Arriving 2023" text */
.arriving-text {
  font-size: 36px; /* Adjust the font size as needed */
  text-transform: uppercase; /* Convert text to uppercase */
  font-weight: bold;
  text-shadow: 6px 6px 5px rgba(0, 0, 0, 0.3); /* Add text shadow for a shadow effect */
  letter-spacing: 2px; /* Adjust letter spacing as needed */
  margin-top: -350px;
  margin-bottom: 100px;
  color: white;
  transform: scale(0.9);
      transform-origin: top;

  padding: 20px; /* Adjust padding as needed */
}
@media (max-width: 767px) {
  .arriving-text {
    margin-right: 0px;
    margin-left: -5px;
    margin-bottom: 000px;

  }
}


.arriving-text5 {
  font-size: 22px; /* Adjust the font size as needed */
  text-transform: uppercase; /* Convert text to uppercase */
  font-weight: bold;
  text-shadow: 6px 6px 5px rgba(0, 0, 0, 0.3); /* Add text shadow for a shadow effect */
  letter-spacing: 2px; /* Adjust letter spacing as needed */
  margin-top: 50px;
  margin-bottom: 50px;
  color: white;
}
@media (max-width: 767px) {
  .arriving-text {
    margin-right: 0px;
    margin-left: -5px;

  }
}

.arriving-text6 {
  font-size: 22px; /* Adjust the font size as needed */
  text-transform: uppercase; /* Convert text to uppercase */
  font-weight: bold;
  text-shadow: 6px 6px 5px rgba(0, 0, 0, 0.3); /* Add text shadow for a shadow effect */
  letter-spacing: 2px; /* Adjust letter spacing as needed */
  margin-top: 150px;
  margin-bottom: 00px;
  color: white;
}
@media (max-width: 767px) {
  .arriving-text6 {
    margin-right: 0px;
    margin-left: -5px;
    margin-top: 150px;
    margin-bottom: 50px;

  }
}

.timeline-position{
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
}

.timeline-position:hover{
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 20px 20px 0 rgba(255, 255, 255, 0.2); /* Light gray shadow */
}


@media (max-width: 767px) {
  .arriving-text5 {
    margin-right: 0px;
    margin-left: -5px;
    margin-top: 100px;
    margin-bottom: 50px;

  }
}
.arriving-text7 {
  font-size: 22px; /* Adjust the font size as needed */
  text-transform: uppercase; /* Convert text to uppercase */
  font-weight: bold;
  text-shadow: 6px 6px 5px rgba(0, 0, 0, 0.3); /* Add text shadow for a shadow effect */
  letter-spacing: 2px; /* Adjust letter spacing as needed */
  margin-top: 100px;
  margin-bottom: 100px;
  color: white;
}
@media (max-width: 767px) {
  .arriving-text7 {
    margin-right: 0px;
    margin-left: -5px;

  }
}



.arriving-text2 {
  font-size: 36px; /* Adjust the font size as needed */
  text-transform: uppercase; /* Convert text to uppercase */
  font-weight: bold;
  text-shadow: 6px 6px 5px rgba(0, 0, 0, 0.3); /* Add text shadow for a shadow effect */
  letter-spacing: 2px; /* Adjust letter spacing as needed */
  margin-top: 100px;
  margin-bottom: 00px;
  color: white;
}
@media (max-width: 767px) {
  .arriving-text2 {
    margin-right: 0px;
    margin-left: -5px;

  }
}

.prototype-button2 {
  background-color: lightcyan; /* Green color */
  color: black;
  border-radius: 15px;
  font-size: 14px; /* Increase the font size to make it bigger */
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 0px solid black;
  border-top: 0px solid black;
  padding: 10px 10px; /* Increase the padding to make it bigger */
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
  width: 20%;
  margin: 20px auto; /* Center the button horizontally */
  margin-top: 30px;
  margin-bottom: 00px;
  cursor: pointer;
}

.prototype-button2:hover {
  background-color: #556B2F; /* Green color */
  color: white;

  border-radius: 15px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  box-shadow: 20px 20px 0 rgba(255, 255, 255, 0.2); /* Light gray shadow */
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
  cursor: pointer;
}

/* Homepage.css */
.prototype-button {
  background-color: lightcyan; /* Green color */
  color: black;
  border-radius: 15px;
  font-size: 20px; /* Increase the font size to make it bigger */
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 0px solid black;
  border-top: 0px solid black;
  padding: 10px 10px; /* Increase the padding to make it bigger */
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
  width: 60%;
  margin: 20px auto; /* Center the button horizontally */
  margin-top: 100px;
  margin-bottom: 100px;
  cursor: pointer;
}

.prototype-button:hover {
  background-color: #556B2F; /* Green color */
  color: white;

  border-radius: 15px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  box-shadow: 20px 20px 0 rgba(255, 255, 255, 0.2); /* Light gray shadow */
  display: block;
  width: 60%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
  cursor: pointer;
}

/* Homepage.css */
.timeline-button {
  background-color: lightcyan; /* Green color */
  color: black;
  border-radius: 15px;
  font-size: 36px; /* Increase the font size to make it bigger */
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 0px solid black;
  border-top: 0px solid black;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
  padding: 50px 160px; /* Increase the padding to make it bigger */
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
  margin: 20px auto; /* Center the button horizontally */
  margin-top: 50px;
  cursor: pointer;
}


.iframe-container{
  justify-content: center;
  max-width: 460px;

  display: flex;
}

.iframe-container2{
  max-width: 800px;
  max-height: 500px;
  margin: 0px auto;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  margin-top: 100px;

  display: block;
}

@media (max-width: 767px) {
  .iframe-container2 {
    margin-top: 100px;
    margin-left: -5px;

  }
}


.iframe-container2:hover{
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 20px 20px 0 rgba(255, 255, 255, 0.2); /* Light gray shadow */

}

.timeline-button:hover {
  background-color: #556B2F; /* Green color */
  color: white;
  border-radius: 15px;
  font-size: 36px; /* Increase the font size to make it bigger */
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  box-shadow: 20px 20px 0 rgba(255, 255, 255, 0.2); /* Light gray shadow */
  padding: 50px 160px; /* Increase the padding to make it bigger */
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
}

.timeline-button2 {
  background-color: black;
  color: white;
  border-radius: 30px;
  font-size: 36px; /* Increase the font size to make it bigger */
  border: 2px solid black;
  box-shadow: 20px 20px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the right and bottom */
  padding: 50px 160px; /* Increase the padding to make it bigger */
  display: block;
  margin: 20px auto; /* Center the button horizontally */
  margin-top: 50px;
  cursor: pointer;
  opacity: 0.3;
}



/* Homepage.css */
/* Homepage.css */
.lang {
  background-color: black;
  color: white;
  border-radius: 10px;
  font-size: 12px;
  border: 2px solid black;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  margin: 20px auto; /* Center the button horizontally */
  cursor: pointer;
  display: block; /* Ensure it takes the full width of its container */
}


/* Add dark mode styles for the homepage */
.dark-mode1 {
  background-color: #000;
  color: #fff;
  border-radius: 0px;
  box-shadow: 20px 20px 20px 20px black;

}

.dark-mode-text {
  background-color: #000;
  color: #fff;
  border-color: white;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.dark-mode-button {
  background-color: white;
  color: black;
  border-color: white;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);

}

/* Add more dark mode styles as needed */


.langWrap{
  margin-top: 120px;
  display: flex;
  padding: 10 10;
}
.iconWrap1{
  display: flex;
  padding-left: 0px;
  justify-content: center;
}

.iconWrap2{
  display: flex;
  padding-left: 0px;
  justify-content: center;
}

.icon1wrap{
  padding-top: 100px;
  margin-left: 100px;
  width: 150px;
}
.icon2wrap{
  padding-top: 100px;
  margin-left: 00px;
  width: 150px;
}

@media (max-width: 767px) {
  .icon1wrap {
    margin-right: 0px;
    width: 80px;
    margin-top: 50px;
    display: none;


  }
}

@media (max-width: 767px) {
  .icon2wrap {
    margin-top: 50px;
    margin-left: -5px;
    width: 80px;

  }
}



.firstConent{
  background-color: darksalmon;
  padding: 10px;
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .firstConent {
    margin-top: 50px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: -5px;

  }
}


@media (max-width: 768px) {
  .prototype-button:hover {
    box-shadow: 10px 10px 0 rgba(255, 255, 255, 0.2); /* Light gray shadow */
    display: block;
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
    cursor: pointer;
  }
}


@media (max-width: 768px) {
  .prototype-button2:hover {
    box-shadow: 10px 10px 0 rgba(255, 255, 255, 0.2); /* Light gray shadow */
    display: block;
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
    cursor: pointer;
  }
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  .button {
    font-size: 20px; /* Further decrease font size for smaller screens */
    padding: 15px 30px; /* Adjust padding for smaller screens */
  }

  .prototype-button,
  .prototype-button2,
  .timeline-button,
  .timeline-button2 {
    font-size: 20px; /* Further decrease font size for mobile */
    padding: 15px 10px; /* Adjust padding for mobile */
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2); /* Smaller shadow for mobile */
    width: 80%;
  }

}


.prototype-buttonw {
 background-color: lightcyan; /* Green color */
 color: black;
 border-radius: 15px;
 font-size: 20px; /* Increase the font size to make it bigger */
 border-right: 2px solid black;
 border-bottom: 2px solid black;
 border-left: 0px solid black;
 border-top: 0px solid black;
 padding: 10px 10px; /* Increase the padding to make it bigger */
 display: block;
 transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
 width: 40%;
 margin: 20px auto; /* Center the button horizontally */
 margin-top: 40px;
 margin-bottom: 10px;
 cursor: pointer;
}

.prototype-buttonw:hover {
 background-color: #556B2F; /* Green color */
 color: white;

 border-radius: 15px;
 border-right: 2px solid black;
 border-bottom: 2px solid black;
 box-shadow: 15px 15px 0 rgba(255, 255, 255, 0.2); /* Light gray shadow */
 display: block;
 transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
 cursor: pointer;
}

@media (max-width: 768px) {
  .prototype-buttonw:hover {
    box-shadow: 10px 10px 0 rgba(255, 255, 255, 0.2); /* Light gray shadow */
    display: block;
    width: 80%;
    padding: 15px 10px; /* Adjust padding for mobile */
    font-size: 20px; /* Further decrease font size for mobile */
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Combine transitions into a single property */
    cursor: pointer;
  }
}


/* Default styles for desktop */
.video-iframe {
  width: 660px;
  height: 415px;
  margin-top: -100px;
}

/* Responsive styles for mobile devices */
@media screen and (max-width: 600px) {
  .video-iframe {
    width: 298px; /* 30% of 660px */
    height: 304.5px; /* 30% of 415px */
    margin-top: -50px; /* Adjusted margin */
  }
}

@media (max-width: 768px) {


  .prototype-buttonw {
    font-size: 20px; /* Further decrease font size for mobile */
    padding: 15px 10px; /* Adjust padding for mobile */
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2); /* Smaller shadow for mobile */
    width: 80%;
  }

}
