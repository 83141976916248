/* CSS for the Balance Sheet container */
.balance-sheet {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  max-width: 1400px;
  max-height: 800px;
  overflow: auto;
  display: block;
  margin-top: 0px;
  border: 2px solid white;
transform: scale(.8);
transform-origin: top;
  margin-left: 870px;
  border-radius: 10px;
  margin-bottom: -80px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
}

.reportTitle{
  color: white;
  margin-left: 800px;
  font-size: 30px;
  margin-top: -800px !important;
  text-align: left;
}

.reportWrapper{
  padding-left: 500px;
  padding-right: 500px;
}

.balance-sheet:hover {
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 #003366;
}

.stateSummaryWrap{
  display: flex;
}
.balance-sheet7 {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  width: 100%;
  position: fixed;

  width: 1250px;
  max-height: 350px;
  overflow: auto;
  margin-top: 50px;
  margin-left: -15px;
  border-radius: 10px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}


.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 400px;
  width: 100%;
}

.ModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.ModalCloseButton {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  color: #333;
}

.ModalCloseButton:hover {
  color: red;
}
.balance-sheet7:hover {
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.balance-sheet2 {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  width: 100%;
  position: fixed;

  max-width: 800px;
  width: 350px;
  max-height: 450px;
  overflow: auto;
  margin-top: 50px;
  margin-left: -15px;
  border-radius: 10px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}

.balance-sheet2:hover {
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.balance-sheet3 {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  width: 100%;
  max-width: 1400px;
  max-height: 450px;
  overflow: auto;
  margin-top: 50px;
  margin-left: 5px;
  border-radius: 10px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}

.balance-sheet3:hover {
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.elementTitle21{
  color: black;
  font-size: 20px;

}

.balance-sheet4 {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  width: 100%;

  max-width: 1050px;
  max-height: 500px;
  overflow: auto;
  margin-top: 50px;
  margin-left: 410px;
  border-radius: 10px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}

.balance-sheet4:hover {
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.statusImage{
  width: 100px;
  height: 100px;
}

.element-status-wrap{
  display: flex;
}

.status-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 200px; /* Set the maximum width to 100px */
  border-right: 1px solid black; /* Add a right border */
  padding: 5px;

}

.elementTitle20 {
  font-size: 16px;
  color: black;
  max-width: 200px; /* Set the maximum width to 100px */
  padding: 5px;
  margin: 25px 0 10px;
  word-wrap: break-word; /* Allow the text to wrap within the width */
}



/* CSS for the element title */
.element-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.elementTitle19{
  font-size: 20px;
  color: white;

  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 100px;
}


.elementTitle5{
  font-size: 22px;
  color: white;
  margin-top: -15px;
  margin-bottom: 10px;
  margin-left: 1300px;
}

.elementTitle9{
  font-size: 22px;
  font-style: italic;
  color: black;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 00px;
}

.error-log{
  color: black;
}

.elementTitle21{
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
  color: black;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 00px;
}

.elementTitle25{
  font-size: 24px;
  font-weight: 500;
  margin-top: 25px;

  font-style: italic;
  color: black;
  margin-bottom: 30px;
  margin-left: 00px;
}

.elementTitle26{
  font-size: 24px;
  font-weight: 500;
  margin-top: 10px;

  font-style: italic;
  color: black;
  margin-bottom: 30px;
  margin-left: 00px;
}

.elementTitle18{
  margin-left: 1500px;
  font-size: 22px;
  color: white;
  margin-top: -70px;
}


.processHeaderwrap{
  margin-left: 200px;
  display: flex;
  margin-top: 40px;
  position: static;
  margin-bottom: 30px;
}
.notes4{
  color: black;
  margin-left: 10px;
  background-color: #ffffff;
  transition: background-color 0.4s ease;
  padding: 5px;
  box-shadow: 5px 5px 0 #003366; /* Add a shadow to the right and bottom */

  border-radius: 5px;

}

.notes6{
  color: black;
  margin-left: 300px;
  margin-top: 100px;
  font-size: 20px;
  background-color: #ffffff;
  transition: background-color 0.4s ease;
  padding: 5px;
  box-shadow: 5px 5px 0 #003366; /* Add a shadow to the right and bottom */

  border-radius: 5px;

}

.notes5{
  color: white;
  margin-left: 10px;
  transition: background-color 0.3s ease;
  padding: 5px;
  border-radius: 5px;

}

.processButtonWrap{
  display: flex;
  margin-left: 5px;
  padding: 5px;
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);

}

.processButton{
  font-size: 19px;
  height: 80px;
  color: black;
  margin-left: 2px;
  background-color: #ffffff;
  transition: background-color 0.4s ease;
  border-radius: 5px;
  box-shadow: 5px 5px 0 #003366;

}

.processButton:hover{
  color: white;
  background-color: #556B2F; /* Green color */
  transition: background-color 0.4s ease;
}

.notes4:hover{
  color: white;
  margin-left: 10px;
  background-color: #556B2F; /* Green color */
  transition: background-color 0.4s ease;

}


.notes6:hover{
  color: white;
  margin-left: 10px;
  background-color: #556B2F; /* Green color */
  transition: background-color 0.4s ease;

}


.elementTitle11{
  font-size: 20px;
  margin-top:15px;
  margin-bottom: -50px;
  margin-left: -100px;
}

.notes{
  padding-left: 10px;

}

.elementDetailWrap{
  margin-left: 40px;
  margin-top: -40px;
}

.notes2{
  padding-left: 10px;

}

.notes3{
  padding-left: 20px;
  margin-left: 5px;
}
.ontologyheader{
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.ontologyheader2{
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}
.elementTitle10{
  font-size: 20px;
  color: white;
  margin-top: 40px;
  margin-bottom: 10px;
  margin-left: 100px;
}

.structureInstanceWrap2{
  margin-top: 100px;
}

.element-title3 {
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin-left: 400px;
  margin-bottom: 20px;
  margin-top: 800px;
}
/* CSS for the balance sheet table */
.balance-sheet-table {
  width: 100%;
  border-collapse: collapse;
}

/* CSS for table headers */
.balance-sheet-table th {
  background-color: #333;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 8px;
}

/* CSS for table cells */
.balance-sheet-table td {
  border: 1px solid black;
  padding: 8px;
  color: black;
  text-align: right;
}

/* CSS for the popup message */
.popup-message {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  z-index: 999;
}

/* CSS for the buttons wrap */
.buttons-wrap {
  margin-top: 0px;
  margin-left: 500px;

}

/* CSS for table buttons */
.table-button {
  background-color: #0074cc;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  max-height: 50px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

/* CSS for the "N/A" button */
.table-button.table-button-na {
  background-color: #ccc;
  cursor: not-allowed;
}

.table-button2 {
  background-color: #0074cc;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  max-height: 50px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

/* CSS for the "N/A" button */
.table-button2.table-button2-na {
  background-color: #ccc;
  cursor: not-allowed;
}
