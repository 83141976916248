.center-div {
  max-width: 800px;
  margin: 80px auto 100px;
  padding: 20px;
  background-color: #f5f5f5;
  text-align: left;
  font-family: 'Arial', sans-serif;
}

.pageAlillef {
  margin: 0 60px;
}

.aboutJinsei, .aboutJinsei69 {
  color: #333;
  margin: 0 40px 40px;
  font-size: 18px;
  line-height: 1.6;
}

.aboutJinsei69 {
  margin-bottom: 40px; /* Adjusted for consistency */
}

.aboutJinsei7 {
   margin: 55px auto 40px;
  font-size: 24px; /* Increased for header */
  text-align: center;
}

@media (max-width: 767px) {
  .aboutJinsei7  {
    margin: 80px auto 40px;
  }
}

.summaryBox {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.summaryBox h2 {
  color: #333;
  margin-bottom: 15px;
}

.summaryBox p {
  color: #666;
}

/* Bullet list styling */
ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0 0 20px 40px;
}

ul li {
  color: #333;
  margin-bottom: 10px;
}

/* Applying consistent margins for various titles and items */
.productTitle, .tradeSecretTitle, .topicTitle {
  margin: 20px 40px 10px;
}

.productSubTitle, .tocItem, .tocItem1 {
  margin-left: 60px;
}

/* Responsive design */
@media (max-width: 767px) {
  .center-div {
    margin: 50px 20px;
  }

  .pageAlillef,
  .aboutJinsei, .aboutJinsei69 {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.xlsx-tablez {
  width: 100%;
  max-width: 1500px;
  margin: 30px auto 40px;
  border-spacing: 0;
  overflow-x: auto;
  background-color: #F8FAFC;
  border-collapse: collapse;
  box-shadow: 5px 5px 0 #003366;
  transition: box-shadow 0.3s ease;
}

.xlsx-tablez:hover {
  box-shadow: 10px 10px 0 #003366;
}

.xlsx-tablez th, .xlsx-tablez td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.xlsx-tablez th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.xlsx-tablez td[contentEditable="true"]:focus {
  outline: none;
  border: 1px solid #007bff;
  background-color: #fff;
  box-shadow: 0 0 5px #003366;
}

.xlsxView {
  width: 820px;
  margin: 0 auto 50px;
}

@media (max-width: 767px) {
  .xlsxView, .xlsx-tablez, .sheetView {
    width: calc(100% - 40px);
    margin-left: 20px;
  }
}

.sheetView {
  width: 320px;
  margin: 20px;
  box-shadow: 5px 5px 0 #003366;
  transition: box-shadow 0.3s ease;
}

.sheetView:hover {
  box-shadow: 10px 10px 0 #003366;
}

/* Simplified for clarity */
.aboutJinsei3, .aboutJinsei4 {
  color: #333;
  margin: 20px 40px 50px;
  text-align: left;
}
