.xlsx-table {
  margin-top: 30px;
  border-bottom: 2px solid #3498db; /* Border color */
  border-right: 2px solid #3498db; /* Border color */

  top: 0;
  padding: 5px;
  display: inline-block;
  margin-left: -40px;
  max-width: 1500px;
  max-height: 400px;
  margin-bottom: -100px;
  border-radius: 10px !important;
  overflow-x: auto;
  background-color: #F8FAFC;
  border-collapse: separate;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 #003366; /* Add a shadow to the right and bottom */

  border-radius: 10px !important;
}

.xlsx-table:hover {
  box-shadow: 20px 20px 0 #003366; /* Add a shadow to the right and bottom */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}

.xlsx-table6 {
  margin-top: 0px;
  border-bottom: 2px solid #3498db; /* Border color */
  border-right: 2px solid #3498db; /* Border color */
  top: 0;
  padding: 5px;
  display: inline-block;
  margin-left: 0px;
  width: 1400px;
  max-height: 400px;
  margin-bottom: 00px;
  border-radius: 10px !important;
  overflow-x: auto;
  background-color: #F8FAFC;
  border-collapse: separate;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 #003366; /* Add a shadow to the right and bottom */

  border-radius: 10px !important;
}

.xlsx-table6:hover {
  box-shadow: 20px 20px 0 #003366; /* Add a shadow to the right and bottom */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}

.xlsx-table4 {
  margin-top: 0px;
  border-bottom: 2px solid #3498db; /* Border color */
  border-right: 2px solid #3498db; /* Border color */
  top: 0;
  padding: 5px;
  display: inline-block;
  margin-left: 0px;
  max-width: 1600px;
  max-height: 400px;
  margin-bottom: 30px;
  border-radius: 10px !important;
  overflow-x: auto;
  background-color: #F8FAFC;
  border-collapse: separate;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 #003366; /* Add a shadow to the right and bottom */

  border-radius: 10px !important;
}

.xlsx-table4:hover {
  box-shadow: 20px 20px 0 #003366; /* Add a shadow to the right and bottom */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}


.processTablesWrapper{
  display: flex;
  margin-left: 500px;
}


.processTablesWrapper2{
  display: flex;
  margin-left: 100px;
  margin-top: 50px;
  transform: scale(0.8); /* Zoom out by 67% */
}


.processTablesWrapper3{
  display: flex;
  margin-top: 0px;

  padding-bottom: 10px;
  margin-left: 100px;
  transform: scale(0.8); /* Zoom out by 67% */
}

.processButton2{
  color: black;
  font-size: 18px;
  margin-top: 100px;
  margin-left: 200px;
  box-shadow: 5px 5px 0 #003366; /* Add a shadow to the right and bottom */

}

.processTablesWrapper5{
  display: flex;
  margin-top: 0px;

  padding-bottom: 10px;
  margin-left: 0px;
  transform: scale(0.8); /* Zoom out by 67% */
}
.processTablesWrapper6{
  display: flex;
  margin-top: 0px;

  padding-bottom: 10px;
  margin-left: 40px;
  transform: scale(0.8); /* Zoom out by 67% */
}




.processTablesWrapper4{
  display: block;
  margin-top: 80px;
  margin-left: 00px;
  transform: scale(1.0); /* Zoom out by 67% */
}
.xlsx-table2 {
  margin-top: 00px;
  border-bottom: 2px solid #3498db; /* Border color */
  border-right: 2px solid #3498db; /* Border color */
  top: 0;
  padding: 5px;
  margin-left:20px;
  max-width: 120px;
  max-height: 400px;
  margin-bottom: -100px;
  border-radius: 10px !important;
  overflow-x: auto;
  background-color: #F8FAFC;
  border-collapse: separate;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 10px 10px 0 #003366; /* Add a shadow to the right and bottom */

  border-radius: 10px !important;
}

.xlsx-table2:hover {
  box-shadow: 15px 15px 0 #003366; /* Add a shadow to the right and bottom */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}




.addrowbutton{
  background-color: lightslategrey;
  color: black;
  margin-top: 100px;
  margin-bottom: 100px;
}
.elementTitle{
  margin-top: 100px;
  margin-bottom: -80px
}



.belowSubRibbon{
  background-color: #F8FAFC;
}

.elementTitle2{
  margin-top: -10px;
  font-size: 22px;
  margin-bottom: 30px;
  margin-left: 1200px;
  color: white;
}

.selected-button {
  background-color: grey !important;
  /* Add any other styles you want for the selected button */
}

.xlsx-table.resizable {
  /* Your existing CSS styles for the resizable table */
  position: relative; /* Add this to enable absolute positioning for resizing handle */
}

.xlsx-table.resizable .resize-handle {
  /* Styling for the resizing handle */
  position: absolute;
  width: 8px; /* Adjust the handle width as needed */
  height: 100%;
  color: black;
  top: 0;
  right: -4px; /* Half of the handle width, so it stays outside the table */
  cursor: col-resize; /* Cursor style for resizing */
  z-index: 1; /* Ensure the handle is above the table content */
  background-color: #ddd; /* Handle color */
}


.xlsx-table2.resizable {
  /* Your existing CSS styles for the resizable table */
  position: relative; /* Add this to enable absolute positioning for resizing handle */
}

.xlsx-table2.resizable .resize-handle {
  /* Styling for the resizing handle */
  position: absolute;
  width: 8px; /* Adjust the handle width as needed */
  height: 100%;
  color: black;
  top: 0;
  right: -4px; /* Half of the handle width, so it stays outside the table */
  cursor: col-resize; /* Cursor style for resizing */
  z-index: 1; /* Ensure the handle is above the table content */
  background-color: #ddd; /* Handle color */
}

.contentWrap {
  margin-top: 250px;
  margin-left: 100px;
  max-width: 500px !important; /* Limit the maximum width to 500px */

  padding: 20px;
}

.table-wrap{
  margin-left: 100px;
}

.table-wrap2{
  margin-left: 100px;
  margin-top: 0px;
}

.cellWrapper{
  display: flex;
}

.unique-menu-container {
  position: relative;
}

.unique-menu-button {
  cursor: pointer;
  background-color: #f5f5f5; /* Subtle background color */
  margin: 10px 10px;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: -5px;
  transition: background-color 0.3s; /* Smooth background color transition */
}

/* Style for the menu button on hover */
.unique-menu-button:hover {
  background-color: #e0e0e0; /* Hover background color */
}

/* Style for the popup menu */
.unique-popup-menu {
  position: absolute;
  top: 100%; /* Position the menu below the cell */
  left: 0;
  overflow-y: auto;
  display: none; /* Initially, hide the menu */
  background-color: rgba(0, 0, 0, 0.8); /* Darker background color */
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift effect */
  border-radius: 5px;
  z-index: 99999;
  padding: 10px;
}

/* Center the buttons */

/* Show the menu when .unique-show is applied to .unique-menu-container */
.unique-menu-show .unique-popup-menu {
  display: flex;
  z-index: 999999;
  overflow-y: visible;
  margin-bottom: 5px;
}

/* Style for the container of buttons at the bottom */
.bottomButtonWrap {
  display: flex;
  margin-left: 5px;
  margin-top: 50px; /* Adjust as needed */
}

/* Hide the menu when .unique-menu-hide is applied to .unique-menu-container */
.unique-menu-hide .unique-popup-menu {
  display: none;
}

/* CSS for green background */
.green-background {
  background-color: lightgoldenrodyellow;
}

.unique-popup-menu {
  /* ... other styles ... */
  text-align: center; /* Center the buttons */
}

.menu-button-cell {
  border: none;
  border-radius: 5px;
  padding: 8px 12px; /* Adjust padding as needed */
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  color: black !important;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  font-size: 14px; /* Adjust font size as needed */
  background-color: #4CAF50; /* Default background color */
  color: #fff; /* Default text color */
  display: inline-block; /* Ensure buttons are displayed in a line */
}

.menu-button-cell:hover {
  background-color: #45a049; /* Hover background color */
}




.table-button {
  margin-top: 10px;
  margin-left: 10px;
  width: 100px;
}
/* XLSXSheetRenderer.css */
.buttons-wrap{
  display: flex;
  margin-left: 100px;
}


.xlsx-table th, .xlsx-table td {
  border: 1px solid #ccc;
  padding: 8px;
  color: black;
  font-weight: 350;

  text-align: left;
}

.xlsx-table th {
  background-color: #f0f0f0;
  color: black;
  font-weight: normal;
}

.xlsx-table td {
  background-color: #ffffff;

}

.xlsx-table td[contentEditable="true"]:focus {
  outline: none;
  border: 1px solid #007bff;
  color: black;
  background-color: #fff;
  box-shadow: 0 0 5px #003366;

}


.xlsx-table2 th, .xlsx-table td {
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 1.1em;
  width: 200px;
  font-weight: 350;
  color: black;
  text-align: left;
}

.xlsx-table2 th {
  background-color: #f0f0f0;
  color: black;
  font-weight: 350;
}

.xlsx-table2 td {
  background-color: #ffffff;

}

.xlsx-table4 td[contentEditable="true"]:focus {
  outline: none;
  border: 1px solid #007bff;
  color: black;
  background-color: #fff;
  box-shadow: 0 0 5px #003366;

}

.xlsx-table4 th, .xlsx-table td {
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 1.1em;
  width: 200px;
  font-weight: 350;
  color: black;
  text-align: left;
}

.xlsx-table4 th {
  background-color: #f0f0f0;
  color: black;
  font-weight: 350;
}

.xlsx-table4 td {
  background-color: #ffffff;

}

.xlsx-table4 td[contentEditable="true"]:focus {
  outline: none;
  border: 1px solid #007bff;
  color: black;
  background-color: #fff;
  box-shadow: 0 0 5px #003366;

}


.xlsx-table6 th, .xlsx-table6 td {
  border: 1px solid #ccc;
  padding: 8px;
  color: black;
  font-weight: 350;
  text-align: left;
}

.xlsx-table6 th {
  background-color: #f0f0f0;
  color: black;
  font-weight: normal;
}

.xlsx-table6 td {
  background-color: #ffffff;
  width: 400px;
}

.xlsx-table6 td[contentEditable="true"]:focus {
  outline: none;
  border: 1px solid #007bff;
  color: black;
  background-color: #fff;
  box-shadow: 0 0 5px #003366;

}



.table-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
}

.table-button:hover {
  background-color: #0056b3;
}


/* XLSXSheetRenderer.css */

/* Add this CSS at the end of your CSS file */

.status-message {
  position: absolute;
  background-color: #333; /* Background color for the status message */
  color: #fff; /* Text color for the status message */
  padding: 5px;
  border: 1px solid #777;
  border-radius: 5px;
  z-index: 1; /* Ensure the status message is displayed above other content */
  margin-top: -30px; /* Adjust the value to position it above the header or table data */
  display: none; /* Initially hide the status message */
}

.table-wrap:hover .status-message {
  display: block; /* Display the status message when .table-wrap is hovered over */
}




.schemaRibbon {
  display: fixed;
  justify-content: space-between;
  background-color: #001522;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 100px;
}

.subRibbon{
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}

.subRibbon:hover {
  box-shadow: 10px 10px 0 #003366; /* Add a shadow to the right and bottom */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */

}

.associationsTreeWrap{
  display: flex
}

.associationsTree{
  display: block
}

.addrowButton{
  margin-top: 100px;
  margin-left: 20px;
  display: block;
  margin-bottom: -100px;

}

.addrowButton {
  margin-top: 90px;
  margin-left: 20px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #556B2F; /* Green color */
  color: white;
  margin-bottom: -100px;

  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  box-shadow: 5px 5px 0 #003366; /* Add a shadow to the right and bottom */

}


.button-wrapAY{
  margin-top: -80px;
  display: flex;
  margin-left: 00px;
}

.button-wrapAY2{
  margin-top: 10px;
  display: flex;
  margin-left: 100px;
}


  .addrowButton:hover {
    background-color: #45a049; /* Darker green color on hover */
    box-shadow: 15px 15px #003366; /* Add a shadow to the right and bottom */
    transition: box-shadow 0.3s ease; /* Smooth transition on hover */

  }


.ribbon-button {
  background-color: #001522; /* Soft pastel blue color */
  border: 2px solid grey;
  color: white;
  font-size: 18px;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 5px;
  margin-top: 20px;
  box-shadow: 5px 5px #003366;
  border-radius: 5px;
}

.ribbon-button:hover {
  background-color: skyblue;
  transition: background-color 0.3s ease; /* Smooth transition on hover */

}
