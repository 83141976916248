.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  width: 100%;
  height: 100%;
}

.container__inner {
  overflow: hidden;
  position: relative;
  width: 80vw;
}

.container__inner::before {
  display: block;
  content: '';
  width: 100%;
  padding-top: 56.25%;
}

@media (max-width: 767px) {
  .container__inner::before {
    margin-top: 70px;
  }
}

@media (max-width: 767px) {
  .container__inner{
    margin-top: 70px;
  }
}



.img__container {
  overflow: hidden;
  position: absolute;
  opacity: .5;
  top: 0;
  width: 100%;
  height: 100%;
}

.img__container > img {
  min-width: 100%;
  max-width: 100%;
}

.ticker {
  position: absolute;
  max-height: 4rem;
  max-width: 4rem;
  width: 3.5vw;
  height: 3.5vw;
  bottom: 2rem;
  left: 2rem;
}

.ticker > * {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.ticker > div {
  opacity: 0.2;
  border: solid 3px black;
}

.ticker > svg {
  fill: none;
  stroke: black;
  stroke-width: 3;
  stroke-dasharray: 120;
  stroke-dashoffset: 120;
}

/* styles.module.css */

.overlayContainer {
  position: absolute;
  top: 650px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0); /* Fully transparent background */
  padding: 20px; /* Add padding for better visibility */
}


@media (max-width: 767px) {
  .overlayContainer {
    margin-top: -400px;


  }
}
